import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Home from './../Pages/Home';
import AboutUs from './../Pages/AboutUs';
// import TermsAndConditions from "./../Pages/TermsAndConditions";
import PrivacyPolicy from './../Pages/PrivacyPolicy';
import Page404 from '../Pages/Page404';
import LayoutWithNavAndFooter from '../Layouts/LayoutWithNavAndFooter';
import ContactUs from '../Pages/ContactUs';
import Stores from '../Pages/Stores';
import NoInternet from '../Pages/NoInternet';
import { useEffect } from 'react';
// import TermsAndConditions from '../Pages/TermsAndConditions';

const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleOffline = () => {
      navigate('/no-internet');
    };
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <Routes>
      <Route element={<LayoutWithNavAndFooter />}>
        <Route index element={<Navigate to={'/home'} />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        {/* <Route path="/terms-and-conditions" element={<TermsAndConditions />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/stores" element={<Stores />} />
      </Route>
      <Route path="*" element={<Page404 />} />
      <Route path="/no-internet" element={<NoInternet />} />
    </Routes>
  );
};

export default AppRoutes;
