export const ar = {
  language: 'EN',
  navbar: {
    home: 'الرئيسية',
    aboutUs: 'معلومات عنا',
    terms: 'الشروط والأحكام',
    privacyPolicy: 'سياسة الخصوصية',
    contactUs: 'تواصل معنا',
  },
  home: {
    firstSection: {
      // title: "أهلا بك فى بيج سكرابرز",
      title: {
        1: 'أهلا بك',
        2: 'فى بيج سكرابرز',
      },
      paragraph: 'شريكك في إعادة التدوير المستدام من أجل مستقبل أكثر خضرة!',
    },
    secondSection: {
      paragraph1:
        'مرحبًا بك في بيج سكرابرز ، بوابتك لتقليل النفايات وإحداث تأثير حقيقي في مجتمعك.',
      // 'يعتبر بيج سكرابرز بوابتك لتقليل النفايات وإحداث تأثير حقيقي. انضم إلى مجتمعنا، وكن صانعًا للتغيير، وقم بتحويل صناعة الألومنيوم من أجل غد أكثر خضرة.',
      paragraph2:
        'انضم إلى الحركة! قم ببيع الخردة الخاصة بك إلى بيج سكرابرز، واحصل على المكافآت وعزز الاقتصاد الدائري من أجل مستقبل أكثر إشراقًا وأكثر اخضرارًا.',
      // 'انضم إلى بيج سكرابرز، مركز الأفراد المهتمين بالبيئة في صناعة خردة وعلب الألومنيوم. تواصل وتعاون واكسب العمولات بينما نحدث ثورة في الاستدامة معًا.',
      visitWebsite: 'زور موقعنا',
    },
    thirdSection: {
      title: 'تطبيق بيج سكرابرز',
      getApplication: 'احصل على تطبيق الهاتف المحمول',
    },
  },
  about: {
    about: 'عن',
    scraption: 'بيج سكرابرز',
    firstSection: {
      1: 'تأسست شركة Big Scrappers في عام 2012 كشركة إعادة تدوير متخصصة في جمع نفايات الألومنيوم. كانت الشركة تعرف في الأصل باسم قبنورى لإعادة التدوير أو قبنورى للتجارة الخردة، ثم انتقلت الشركة مؤخرًا إلى Big Scrappers، لتوسيع خدماتها لجمع مجموعة واسعة من المواد القابلة لإعادة التدوير.',
      2: 'تتضمن عمليتنا الأساسية شراء وجمع النفايات من الأفراد والشركات لتعزيز الاقتصاد الدائري مع تحفيز أولئك الذين يشاركون في مبادرتنا بمكافآت نقدية أو نقاط يمكنهم استردادها من سوقنا.',
    },
    secondSection: {
      whatWeDo: 'ماذا نفعل؟',
      1: 'نحن نركز على الحصول على مجموعة من المواد B2B و B2C مثل المعدن والبلاستيك والبلاستيك المختلط والعلب والصلب وقطع غيار السيارات والإلكترونيات والفولاذ المقاوم للصدأ والنحاس الأحمر والنحاس الأصفر والإطارات والكابلات والزيوت المستعملة والرقائق والسيارات البطاريات، بطاريات الدراجات النارية، البلاستيك الصلب، الورق المقوى، الزجاج، نفايات الألومنيوم وغيرها من المواد. من خلال جمع هذه المواد ومعالجتها في ساحات القمامة لدينا، نقوم بإنشاء اقتصاد دائري يقلل من النفايات ويطيل عمر العناصر ويعزز الاستدامة في المجتمعات التي نعمل فيها.',
    },
    mission: {
      mission: 'مهمتنا',
      section:
        'مهمتنا هي قيادة الطريق في الإدارة المستدامة للنفايات من خلال تحويل المواد القابلة لإعادة التدوير إلى موارد قيمة.',
    },
    vision: {
      vision: 'رؤيتنا',
      section:
        'رؤيتنا هي أن نصبح شركة رائدة في إعادة التدوير والإدارة المستدامة للنفايات في الشرق الأوسط، معترف بها لنهجنا المبتكر والتزامنا الثابت بالحفاظ على البيئة',
    },
    joinSection: {
      joinUs: 'إنضم إلينا فى',
      description:
        'وكن جزءًا من الحل. معًا، يمكننا تشكيل مستقبل أكثر اخضرارًا، حيث يتم تعظيم قيمة الألومنيوم، وتقليل النفايات، ويزدهر كوكبنا.',
    },
    ourValues: {
      title: 'قيمنا الأساسية هي:',
      section1: {
        title: 'الإشراف البيئي',
        paragraph:
          'نحن ملتزمون بحماية البيئة من خلال تقليل النفايات وتشجيع إعادة التدوير، وبالتالي ضمان كوكب أنظف وأكثر صحة للأجيال القادمة.',
      },
      section2: {
        title: 'الاستدامة',
        paragraph:
          'يكمن التزامنا في تعزيز الاقتصاد الدائري من خلال إعادة تدوير مجموعة متنوعة من المواد، وتحويل النفايات إلى موارد قيمة.',
      },
      section3: {
        title: 'ابتكار',
        paragraph:
          'منذ بدايتنا كشركة قبنورى لإعادة التدوير إلى هويتنا الحالية كشركة Big Scrappers، فإننا نتبع باستمرار أساليب مبتكرة لتعزيز وتوسيع عمليات وخدمات إعادة التدوير لدينا.',
      },
      section4: {
        title: 'مركزية العملاء',
        paragraph:
          'تم تصميم عملياتنا لتكون مريحة ومجزية لعملائنا، مما يسهل تجربة إعادة التدوير السهلة والمفيدة.',
      },
      section5: {
        title: 'النمو الاقتصادي',
        paragraph:
          'ومن خلال شراء مواد النفايات من الأفراد والشركات، نقوم بإنشاء حوافز مالية تدعم الاقتصادات المحلية وتعزز الممارسات المستدامة.',
      },
    },
    videoSection: {
      description:
        'ليست مجرد منصة؛ إنها حركة. نحن نؤمن أنه من خلال تسخير قوة التكنولوجيا والتعاون، يمكننا أن نحدث فرقًا ملموسًا في العالم. تتمثل مهمتنا في إنشاء شبكة عالمية من الأفراد ذوي التفكير المماثل والملتزمين باتخاذ خيارات مستدامة وقيادة التغيير الإيجابي.',
    },
  },
  privacyPolicy: {
    title: 'سياسة الخصوصية',
    lastUpdate: 'آخر تحديث: 24 مارس 2024',
    description:
      'توضح سياسة الخصوصية هذه سياساتنا وإجراءاتنا فيما يتعلق بجمع واستخدام وكشف معلوماتك عند استخدامك للخدمة وتخبرك عن حقوق خصوصيتك وكيف يحميك القانون.',
    interpretationAndDefinitions: {
      title: 'التفسير والتعاريف',
      interpretation: {
        title: 'التفسير',
        description:
          'لهذه الكلمات التي تبدأ بحرف كبير معاني محددة تحدد في الشروط التالية. يجب أن تكون هذه التعريفات لها نفس المعنى بغض النظر عما إذا كانت في صيغة المفرد أو الجمع.',
      },
      definitions: {
        title: 'التعاريف',
        forThePurpose: 'لأغراض سياسة الخصوصية هذه:',
        titles: {
          account: 'الحساب',
          affiliate: 'الشريك',
          application: 'التطبيق',
          company: 'الشركة',
          country: 'البلد',
          device: 'الجهاز',
          personalData: 'البيانات الشخصية',
          service: 'الخدمة',
          serviceProvider: 'مزود الخدمة',
          usageData: 'بيانات الاستخدام',
          you: 'أنت',
        },
        descriptions: {
          account:
            'يعني حساب فريد تم إنشاؤه لك للوصول إلى خدمتنا أو أجزاء من خدمتنا.',
          affiliate:
            "يعني كيان يسيطر عليه الشركة أو يتم التحكم فيه من قبلها أو يكون تحت السيطرة المشتركة مع طرف آخر، حيث يعني 'السيطرة' امتلاك 50٪ أو أكثر من الأسهم أو حصة المال أو الأوراق المالية الأخرى المؤهلة للتصويت لانتخاب المديرين أو أي سلطة إدارية أخرى.",
          application:
            'يشير إلى بيج سكرابرز Merchant، برنامج البرمجيات المقدم من الشركة.',
          company:
            "(المشار إليها بـ 'الشركة' أو 'نحن' أو 'نا' في هذه الاتفاقية) يشير إلى بيج سكرابرز ، Zayed ، Galleria 40.",
          country: 'يشير إلى: مصر',
          device:
            'يعني أي جهاز يمكنه الوصول إلى الخدمة مثل الكمبيوتر أو الهاتف المحمول أو الجهاز اللوحي الرقمي.',
          personalData: 'أي معلومات تتعلق بشخص محدد أو قابل للتحديد.',
          service: 'يشير إلى التطبيق.',
          serviceProvider:
            'يعني أي شخص طبيعي أو معنوي يعالج البيانات نيابة عن الشركة. يشير إلى الشركات أو الأفراد اليعملون بالشركة من الجهات الخارجية والموظفين من قبل الشركة لتسهيل الخدمة وتقديم الخدمة نيابة عن الشركة أو تنفيذ الخدمات المتعلقة بالخدمة أو مساعدة الشركة في تحليل كيفية استخدام الخدمة.',
          usageData:
            'تشير إلى البيانات التي تم جمعها تلقائيًا ، سواء تم إنشاؤها بواسطة استخدام الخدمة أو من بنية الخدمة نفسها (على سبيل المثال ، مدة زيارة صفحة).',
          you: 'تعني الشخص الذي يستخدم الخدمة أو الشركة أو الكيان القانوني الآخر الذي يمثل هذا الشخص ويستخدم الخدمة، حسب الاقتضاء.',
        },
      },
    },
    collectingAndUsingYourPersonalData: {
      title: 'جمع واستخدام البيانات الشخصية الخاصة بك',
      types: {
        title: 'أنواع البيانات المجمعة',
        personal: {
          title: 'البيانات الشخصية',
          description:
            'أثناء استخدام خدمتنا ، قد نطلب منك تزويدنا ببعض المعلومات الشخصية التي يمكن استخدامها للاتصال بك أو تحديد هويتك. قد تشمل المعلومات الشخصية ، على سبيل المثال لا الحصر:',
          name: 'الاسم الأول والاسم الأخير',
          phone: 'رقم الهاتف',
          usageData: 'بيانات الاستخدام',
        },
        usageData: {
          title: 'بيانات الاستخدام',
          points: {
            1: 'تتم جمع بيانات الاستخدام تلقائيًا عند استخدام الخدمة.',
            2: 'قد تتضمن بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت الخاص بجهازك (عنوان IP)، نوع المتصفح، إصدار المتصفح، صفحات خدمتنا التي تزورها، وقت وتاريخ زيارتك، الوقت الذي قضيته على تلك الصفحات، ومعرفات الأجهزة الفريدة وبيانات تشخيصية أخرى.',
            3: 'عند الوصول إلى الخدمة عن طريق جهاز محمول، قد نقوم بجمع معلومات معينة تلقائيًا، بما في ذلك نوع الجهاز المحمول الذي تستخدمه، معرف الجهاز المحمول الخاص بك، عنوان IP لجهازك المحمول، نظام تشغيل جهازك المحمول، نوع متصفح الإنترنت المحمول الذي تستخدمه، ومعرفات الأجهزة الفريدة وبيانات تشخيصية أخرى.',
            4: 'قد نقوم أيضًا بجمع المعلومات التي يرسلها متصفحك عند زيارتك لخدمتنا أو عند الوصول إلى الخدمة عن طريق جهاز محمول.',
          },
        },
      },
      useOfPersonalData: {
        title: 'استخدام بياناتك الشخصية',
        purposes: {
          description: 'قد تستخدم الشركة البيانات الشخصية للأغراض التالية:',
          1: {
            title: 'توفير وصيانة خدمتنا',
            description: '، بما في ذلك مراقبة استخدام خدمتنا.',
          },
          2: {
            title: 'إدارة حسابك:',
            description:
              'لإدارة تسجيلك كمستخدم للخدمة. يمكن أن تمنحك البيانات الشخصية التي تقدمها وصولًا لوظائف مختلفة في الخدمة المتاحة لك كمستخدم مسجل.',
          },
          3: {
            title: 'لأداء عقد:',
            description:
              'تطوير وامتثال وتنفيذ عقد الشراء للمنتجات أو العناصر أو الخدمات التي قمت بشرائها أو أي عقد آخر معنا من خلال الخدمة.',
          },
          4: {
            title: 'للتواصل معك:',
            description:
              'للتواصل معك عن طريق البريد الإلكتروني، المكالمات الهاتفية، الرسائل القصيرة (SMS)، أو أشكال الاتصال الإلكتروني المعادلة الأخرى، مثل إشعاراستخدام التطبيق بخصوص التحديثات أو الاتصالات المعلوماتية ذات الصلة بالوظائف أو المنتجات أو الخدمات المتعاقد عليها، بما في ذلك تحديثات الأمان عند الضرورة أو الاعتبار المعقول لتنفيذها.',
          },
          5: {
            title: 'لتزويدك',
            description:
              'بالأخبار والعروض الخاصة والمعلومات العامة حول السلع والخدمات والأحداث الأخرى التي نقدمها وتشابه الأشياء التي قمت بشرائها أو الاستفسار عنها، ما لم تقرر عدم تلقي مثل هذه المعلومات.',
          },
          6: {
            title: 'لإدارة طلباتك:',
            description: 'للتعامل مع طلباتك وإدارتها.',
          },
          7: {
            title: 'لعمليات النقل التجارية:',
            description:
              'قد نستخدم معلوماتك لتقييم أو إجراء عملية دمج، أو بيع أصول الشركة، أو تمويل، أو استحواذ على كل أو جزء من أعمالنا من قبل شركة أخرى، سواء ككيان قائم أو كجزء من إجراءات الإفلاس، التصفية، أو إجراء مماثل، حيث تكون البيانات الشخصية التي تحتفظ بها عن مستخدمي خدمتنا من بين الأصول المنقولة.',
          },
          8: {
            title: 'لأغراض أخرى:',
            description:
              'قد نستخدم معلوماتك لأغراض أخرى مثل تحليل البيانات وتحديد اتجاهات الاستخدام وتحديد فعالية حملات الترويجية وتقييم وتحسين خدمتنا ومنتجاتنا وخدماتنا وتسويقنا وتجربتك.',
          },
        },
        situations: {
          description: 'قد نشارك معلوماتك الشخصية في الحالات التالية:',
          1: {
            title: 'مع مزودي الخدمة:',
            description:
              'قد نشارك معلوماتك الشخصية مع مزودي الخدمة لمراقبة وتحليل استخدام خدمتنا وللتواصل معك.',
          },
          2: {
            title: 'لعمليات النقل التجارية:',
            description:
              'قد نشارك أو ننقل معلوماتك الشخصية فيما يتعلق بأي عمليات دمج أو بيع لأصول الشركة أو تمويل أو استحواذ على جميع أو جزء من أعمالنا إلى شركة أخرى خلال عمليات التفاوض.',
          },
          3: {
            title: 'مع الشركات التابعة:',
            description:
              'قد نشارك معلوماتك مع الشركات التابعة لنا، وفي هذه الحالة سنطلب من تلك الشركات أن تلتزم بهذه سياسة الخصوصية. تشمل الشركات التابعة لنا الشركة الأم وأي شركات تابعة أخرى أو شركاء في المشروع المشترك أو أي شركات أخرى تحت سيطرتنا المشتركة.',
          },
          4: {
            title: 'مع شركاء الأعمال:',
            description:
              'قد نشارك معلوماتك مع شركاء الأعمال لنا لتقديم لك منتجات أو خدمات معينة أو عروض ترويجية.',
          },
          5: {
            title: 'مع مستخدمين آخرين:',
            description:
              'عندما تشارك معلومات شخصية أو تتفاعل بأي شكل آخر في المناطق العامة مع مستخدمين آخرين، فقد يتم عرض مثل تلك المعلومات من قبل جميع المستخدمين وقد يتم توزيعها علنًا.',
          },
          6: {
            title: 'بموافقتك:',
            description: 'قد نكشف عن معلوماتك الشخصية لأي غرض آخر بموافقتك.',
          },
        },
      },
      retentionOfPersonalData: {
        title: 'احتفاظ ببياناتك الشخصية',
        points: {
          1: 'سوف تحتفظ الشركة ببياناتك الشخصية فقط طالما كان ذلك ضروريًا لأغراض محددة وفقًا لسياسة الخصوصية هذه. سنحتفظ ببياناتك الشخصية وسنستخدمها للقدر اللازم من أجل الامتثال لالتزاماتنا القانونية (على سبيل المثال، إذا كنا ملزمين بالاحتفاظ ببياناتك للامتثال للقوانين السارية)، وحل النزاعات، وتنفيذ اتفاقياتنا القانونية وسياساتنا.',
          2: 'سوف تحتفظ الشركة أيضًا ببيانات الاستخدام لأغراض التحليل الداخلي. تُحتفظ ببيانات الاستخدام عمومًا لفترة أقصر، ما لم يتم استخدام تلك البيانات لتعزيز الأمان أو تحسين وظائف خدمتنا، أو إلا إذا كنا ملزمين قانونيًا بالاحتفاظ بهذه البيانات لفترات زمنية أطول.',
        },
      },
      transferOfYourPersonalData: {
        title: 'نقل بياناتك الشخصية',
        points: {
          1: 'يتم معالجة معلوماتك، بما في ذلك البيانات الشخصية، في مكاتب الشركة وفي أي مكان آخر يتواجد فيه الأطراف المشاركة في المعالجة. وهذا يعني أن هذه المعلومات قد يتم نقلها إلى أجهزة الكمبيوتر الموجودة خارج دولتك أو مقاطعتك أو بلدك أو سلطة حكومية أخرى حيث قد تختلف قوانين حماية البيانات عن تلك الموجودة في سلطتك القضائية.',
          2: 'موافقتك على سياسة الخصوصية هذه تعني موافقتك على هذا النقل بعد تقديمك لهذه المعلومات.',
          3: 'ستتخذ الشركة جميع الخطوات اللازمة بما فيها الكفاية لضمان معاملة بياناتك بشكل آمن ووفقًا لهذه السياسة الخصوصية، ولن يتم نقل أي بيانات شخصية لك إلى منظمة أو بلد ما ما لم يكن هناك ضوابط كافية في مكانها بما في ذلك أمان بياناتك ومعلوماتك الشخصية الأخرى.',
        },
      },
      deleteOfPersonalData: {
        title: 'حذف بياناتك الشخصية',
        points: {
          1: 'لديك الحق في حذف البيانات الشخصية التي قمنا بجمعها عنك أو طلب مساعدتنا في حذفها.',
          2: 'قد يتيح لك خدمتنا القدرة على حذف بعض المعلومات عنك من خلال الخدمة ذاتها.',
          3: 'يمكنك تحديث أو تعديل أو حذف معلوماتك في أي وقت عن طريق تسجيل الدخول إلى حسابك، إذا كان لديك، وزيارة قسم إعدادات الحساب الذي يتيح لك إدارة معلوماتك الشخصية. يمكنك أيضًا الاتصال بنا لطلب الوصول إلى أو تصحيح أو حذف أي معلومات شخصية قدمتها لنا.',
          4: 'يرجى ملاحظة، ومع ذلك، أنه قد يكون هناك حاجة للحفاظ على بعض المعلومات عندما يكون لدينا التزام قانوني أو أساس قانوني للقيام بذلك.',
        },
      },
      disclosureOfPersonalData: {
        title: 'الكشف عن بياناتك الشخصية',
        businessTransactions: {
          title: 'المعاملات التجارية',
          description:
            'إذا كانت الشركة مشاركة في عملية اندماج، أو استحواذ، أو بيع أصول، فقد يتم نقل بياناتك الشخصية. سنقدم إشعارًا قبل نقل بياناتك الشخصية وتحتوي على سياسة خصوصية مختلفة.',
        },
        lawEnforcement: {
          title: 'إنفاذ القانون',
          description:
            'في ظروف معينة، قد يتعين على الشركة الكشف عن بياناتك الشخصية إذا كان ذلك مطلوبًا بموجب القانون أو استجابة لطلبات صالحة من السلطات العامة (مثل المحكمة أو جهة حكومية).',
        },
        otherLegalRequirements: {
          title: 'متطلبات قانونية أخرى',
          description:
            'قد تكشف الشركة بياناتك الشخصية في حالة الاعتقاد الحسن بأن مثل هذا الإجراء ضروري لـ:',
          points: {
            1: 'الامتثال لالتزام قانوني',
            2: 'حماية ودفاع حقوق أو ممتلكات الشركة',
            3: 'منع أو التحقيق في ارتكاب سلوك غير قانوني محتمل فيما يتعلق بالخدمة',
            4: 'حماية السلامة الشخصية لمستخدمي الخدمة أو الجمهور',
            5: 'الحماية من المسؤولية القانونية',
          },
        },
      },
      securityOfPersonalData: {
        title: 'أمان بياناتك الشخصية',
        description:
          'يعتبر أمان بياناتك الشخصية أمرًا مهمًا بالنسبة لنا، ولكن يرجى تذكر أنه لا يوجد أي طريقة لنقل البيانات عبر الإنترنت أو طريقة لتخزينها إلكترونيًا تكون آمنة بنسبة 100٪. على الرغم من أننا نسعى لاستخدام وسائل مقبولة تجاريًا لحماية بياناتك الشخصية، إلا أننا لا نستطيع ضمان أمانها المطلق.',
      },
    },
    childrenPrivacy: {
      title: 'خصوصية الأطفال',
      points: {
        1: 'لا تتناول خدمتنا أي شخص دون سن 13 عامًا. نحن لا نقوم عن عمد بجمع معلومات شخصية تعرف عليها أي شخص دون سن 13 عامًا. إذا كنت والدي أو وصي وتعلمت أن طفلك قد قدم لنا بيانات شخصية، يرجى الاتصال بنا. إذا أدركنا أننا قد جمعنا بيانات شخصية من أي شخص دون سن 13 عامًا دون التحقق من موافقة الوالدين، فإننا نتخذ خطوات لإزالة تلك المعلومات من خوادمنا.',
        2: 'إذا كان علينا أن نعتمد على الموافقة كأساس قانوني لمعالجة معلوماتك وتتطلب بلدك موافقة من الوالدين، فقد نحتاج إلى موافقة والديك قبل جمع واستخدام تلك المعلومات.',
      },
    },
    linksToOtherWebsites: {
      title: 'روابط إلى مواقع ويب أخرى',
      points: {
        1: 'قد تحتوي خدمتنا على روابط إلى مواقع ويب أخرى لا تعمل بواسطتنا. إذا قمت بالنقر على رابط طرف ثالث، ستتم توجيهك إلى موقع هذا الطرف الثالث. ننصحك بشدة بمراجعة سياسة الخصوصية لكل موقع تزوره.',
        2: 'لا نمتلك أي سيطرة على محتوى أو سياسات الخصوصية أو ممارسات أي موقع طرف ثالث أو خدمة، ولا نتحمل أي مسؤولية عن محتوى تلك المواقع الطرفية أو سياساتها الخصوصية أو ممارساتها.',
      },
    },
    changesToPrivacyPolicy: {
      title: 'تغييرات على سياسة الخصوصية',
      points: {
        1: 'قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. سنقوم بإخطارك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة.',
        2: `سنقوم بإعلامك عبر البريد الإلكتروني و/أو إشعار بارز على خدمتنا، قبل أن يصبح التغيير ساري المفعول وسنقوم بتحديث تاريخ "آخر تحديث" في أعلى هذه سياسة الخصوصية.`,
        3: 'يُنصح بمراجعة سياسة الخصوصية هذه بشكل دوري لأية تغييرات. تكون التغييرات على سياسة الخصوصية سارية المفعول عند نشرها على هذه الصفحة.',
      },
    },
    contactUs: {
      title: 'اتصل بنا',
      submit: 'ارسال',
      fields: {
        name: 'اسم',
        mobile: 'رقم الهاتف',
        email: 'بريد إلكتروني',
        message: 'رسالة',
        inquirey: 'استعلام',
        companyName: 'اسم الشركة',
      },
      placeHolders: {
        mobile: '(+20)',
        inquirey: 'اختر الاستعلام',
        message: 'أكتب رسالتك..',
      },
      validation: {
        name: 'يرجى إدخال اسم صالح.',
        inquiry: 'يرجى اختيار نوع الاستفسار.',
        phone: 'يرجى إدخال رقم هاتف .',
        phoneNotValid: 'يرجى إدخال رقم هاتف صالح.',
        email: 'يرجى إدخال عنوان بريد إلكتروني صالح.',
        message: 'يرجى إدخال رسالتك.',
      },
    },
  },
  page404: {
    heading: 'حدث خطأ ما!',
    paragraph:
      'هذه الصفحة غير موجودة أو تمت إزالتها، نقترح عليك العودة إلى الرئيسية..',
    link: 'العودة إلى الرئيسية',
  },
  footer: {
    bigScrappersWebsite: 'بيج سكرابرز',
  },

  contactUs: {
    title: 'اتصل بنا',
    description:
      'إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه، يمكنك الاتصال بنا:',
    points: {
      1: 'عن طريق رقم الهاتف: 201100820963+',
    },
    buyScrap: 'شراء خردة',
    sellScrap: 'بيع الخردة',
  },

  stores: {
    title: 'المخازن',
    noActiveStores: 'لا توجد متاجر نشطة بعد، نأسف لذلك',
  },

  noData: 'لا يوجد بيانات',

  noInternet: {
    lostConnection: 'تم فقدان الاتصال!',
    oops: 'عذرًا! يبدو أن الاتصال بالإنترنت قد انقطع.يرجى التأكد من اتصالك بالشبكة والمحاولة مرة أخرى',
    tryAgain: 'حاول مرة أخرى',
  },
  // contactUs: {
  //   message: 'الرسالة',
  //   enterMessage: 'ادخل الرسالة',
  //   requiredMessage: 'الرسالة مطلوبة',
  //   mobile: 'رقم الهاتف',
  //   enterMobile: 'ادخل رقم الهاتف',
  //   requiredMobile: 'رقم الهاتف مطلوب',
  //   invalidMobile: 'من فضلك, ادخل رقم هاتف صحيح',
  //   email: 'البريد الالكتروني',
  //   enterEmail: 'ادخل البريد الالكتروني',
  //   requiredEmail: 'البريد الالكتروني مطلوب',
  //   invalidEmail: 'من فضلك, ادخل بريد الكتروني صحيح',
  //   send: 'ارسل',
  //   sentSuccessfully: 'تم الارسال بنجاح!',
  // },
};
