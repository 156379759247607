export const hr = {
  language: 'عربي',
  navbar: {
    aboutUs: 'O nama',
    home: 'Dom',
    terms: 'Odredbe i uvjeti',
    privacyPolicy: 'Politika privatnosti',
    contactUs: 'Kontaktirajte nas',
  },
  home: {
    firstSection: {
      // title: "Welcome to Big Scrappers",
      title: {
        1: 'Hej ti ',
        2: 'U Big Scrappers',
      },
      // paragraph: "Your Partner In Sustainable Aluminum Recycling",
      paragraph: 'Vaš partner u održivoj reciklaži za zeleniju budućnost!',
    },
    secondSection: {
      paragraph1:
        'Dobrodošli u Big Scrappers, vašu kapiju za smanjenje otpada i stvaranje stvarnog uticaja u vašoj zajednici.',

      paragraph2:
        'Pridružite se pokretu! Prodajte svoj otpad Big Scrappers-ima, osvojite nagrade i promovirajte kružnu ekonomiju za svjetliju i zeleniju budućnost.',
      visitWebsite: 'Preuzmite našu mobilnu aplikaciju',
    },
    thirdSection: {
      title: 'Big Scrappers App',
      getApplication: 'Preuzmite našu mobilnu aplikaciju',
    },
  },
  about: {
    about: 'O',
    scraption: 'Big Scrappers',
    firstSection: {
      1: 'Big Scrappers je osnovan 2012. godine kao kompanija za reciklažu specijalizovana za sakupljanje aluminijumskog otpada. Prvobitno poznata kao Kabnoury Recycling ili Kabnoury Le Togaret El Khorda, kompanija je nedavno prešla u Big Scrappers, proširujući svoje usluge na prikupljanje šireg spektra materijala koji se mogu reciklirati.',
      2: 'Naša osnovna djelatnost uključuje kupovinu i prikupljanje otpada od pojedinaca i kompanija kako bismo podstakli kružnu ekonomiju, a istovremeno podstaknuli one koji učestvuju u našoj inicijativi novčanim nagradama ili bodom koje mogu otkupiti na našem tržištu.',
    },
    secondSection: {
      whatWeDo: 'Šta radimo?',
      1: 'Fokusirani smo na nabavku niza materijala B2B i B2C kao što su metal, plastika, miješana plastika, limenke, čelik, auto dijelovi, elektronika, nehrđajući čelik, crveni bakar, žuti bakar, gume, kablovi, rabljeno ulje, folija, auto akumulatori, akumulatori za motocikle, tvrda plastika, karton, staklo, aluminijski otpad i drugi materijali. Sakupljanjem i obradom ovih materijala u našim deponijama stvaramo cirkularnu ekonomiju koja smanjuje otpad, produžava životni vijek predmeta i promovira održivost u zajednicama u kojima poslujemo.',
    },
    mission: {
      mission: 'Naša misija',
      section:
        'Naša misija je da predvodimo održivo upravljanje otpadom pretvarajući materijale koji se mogu reciklirati u vrijedne resurse.',
    },
    vision: {
      vision: 'Naša vizija',
      section:
        'Naša vizija je postati lider u reciklaži i održivom upravljanju otpadom na Bliskom istoku, prepoznati po našim inovativnim pristupima i nepokolebljivoj predanosti očuvanju okoliša',
    },
    joinSection: {
      joinUs: 'Pridružite nam se na',
      description:
        'i budite dio rješenja. Zajedno možemo oblikovati zeleniju budućnost, u kojoj je vrijednost aluminijuma maksimizirana, otpad minimiziran, a naša planeta uspijeva.',
    },
    ourValues: {
      title: 'Naše osnovne vrijednosti su:',
      section1: {
        title: 'Environmental Stewardship',
        paragraph:
          'Posvećeni smo zaštiti okoliša minimiziranjem otpada i promoviranjem recikliranja, čime osiguravamo čistiju i zdraviju planetu za buduće generacije.',
      },
      section2: {
        title: 'Održivost',
        paragraph:
          'Naša predanost leži u podsticanju cirkularne ekonomije recikliranjem raznolikog niza materijala, pretvarajući otpad u vrijedne resurse.',
      },
      section3: {
        title: 'Inovacija',
        paragraph:
          'Od našeg početka kao Kabnoury Recycling do našeg trenutnog identiteta kao Big Scrappers, mi dosljedno slijedimo inovativne metode za poboljšanje i proširenje naših procesa i usluga recikliranja.',
      },
      section4: {
        title: 'Usmjerenost na kupca',
        paragraph:
          'Naše operacije su dizajnirane da budu i zgodne i korisne za naše klijente, omogućavajući lako i korisno iskustvo recikliranja.',
      },
      section5: {
        title: 'Ekonomski rast',
        paragraph:
          'Kupovinom otpadnog materijala od pojedinaca i preduzeća stvaramo finansijske poticaje koji podržavaju lokalnu ekonomiju i promovišu održive prakse.',
      },
    },
    videoSection: {
      description:
        'Nije samo platforma; to je pokret. Vjerujemo da iskorištavanjem snage tehnologije i suradnje možemo napraviti opipljivu promjenu u svijetu. Naša misija je stvoriti globalnu mrežu istomišljenika koji su posvećeni donošenju održivih izbora i pokretanju pozitivnih promjena.',
    },
  },
  privacyPolicy: {
    title: 'Politika Privatnosti',
    lastUpdate: 'Posljednje ažuriranje: 24. mart, 2024',
    description:
      'Ova Politika Privatnosti opisuje Naše politike i procedure o prikupljanju, korištenju i otkrivanju Vaših informacija kada koristite Uslugu i obavještava Vas o Vašim pravima privatnosti i kako Vas zakon štiti.',
    interpretationAndDefinitions: {
      title: 'Tumačenje i Definicije',
      interpretation: {
        title: 'Tumačenje',
        description:
          'Riječi kod kojih je početno slovo veliko imaju značenja definirana pod sljedećim uslovima. Sljedeće definicije imaju isto značenje bez obzira da li se pojavljuju u jednini ili množini.',
      },
      definitions: {
        title: 'Definicije',
        forThePurpose: 'Za potrebe ove Politike Privatnosti:',
        titles: {
          account: 'Račun',
          affiliate: 'Povezani Subjekt',
          application: 'Aplikacija',
          company: 'Kompanija',
          country: 'Država',
          device: 'Uređaj',
          personalData: 'Lični Podaci',
          service: 'Usluga',
          serviceProvider: 'Pružalac Usluge',
          usageData: 'Podaci o Korištenju',
          you: 'Vi',
        },
        descriptions: {
          account: `znači jedinstveni račun kreiran za Vas kako biste pristupili našoj Usluzi ili njenim dijelovima.`,
          affiliate: `znači entitet koji kontroliše, koji je pod kontrolom ili pod zajedničkom kontrolom sa stranom, gdje "kontrola" znači vlasništvo nad 50% ili više dionica, kapitala ili drugih vrijednosnih papira koji daju pravo glasa za izbor direktora ili drugih organa upravljanja.`,
          application: `odnosi se na Big Scrappers Merchant, softverski program koji pruža Kompanija.`,
          company: `(nazivana ili "Kompanija", "Mi", "Nas" ili "Naš" u ovom Ugovoru) odnosi se na Big Scrappers, Zayed, Galleria 40.`,
          country: `odnosi se na: Egipat`,
          device: `znači bilo koji uređaj koji može pristupiti Usluzi, kao što je računar, mobilni telefon ili digitalni tablet.`,
          personalData: `su sve informacije koje se odnose na identificiranu ili prepoznatljivu osobu.`,
          service: `odnosi se na Aplikaciju.`,
          serviceProvider: `znači bilo koja fizička ili pravna osoba koja obrađuje podatke u ime Kompanije. Odnosi se na treće strane kompanije ili pojedince angažirane od strane Kompanije radi olakšavanja Usluge, pružanja Usluge u ime Kompanije, izvršavanja usluga povezanih s Uslugom ili pomaganja Kompaniji u analizi kako se Usluga koristi.`,
          usageData: `odnosi se na podatke koji se automatski prikupljaju, bilo da su generirani korištenjem Usluge ili iz same infrastrukture Usluge (na primjer, trajanje posjete stranici).`,
          you: `znači osoba koja pristupa ili koristi Uslugu, ili kompanija ili druga pravna osoba u ime koje ta osoba pristupa ili koristi Uslugu, gdje je primjenjivo.`,
        },
      },
    },

    collectingAndUsingYourPersonalData: {
      title: 'Prikupljanje i Korištenje Vaših Ličnih Podataka',
      types: {
        title: 'Tipovi Prikupljenih Podataka',
        personal: {
          title: 'Lični Podaci',
          description:
            'Tokom korištenja Naše Usluge, možemo Vas zamoliti da nam pružite određene lične podatke koji se mogu koristiti za kontaktiranje ili identifikaciju Vas. Lični podaci mogu uključivati, ali nisu ograničeni na:',
          name: 'Ime i prezime',
          phone: 'Broj telefona',
          usageData: 'Podaci o korištenju',
        },
        usageData: {
          title: 'Podaci o Korištenju',
          points: {
            1: 'Podaci o korištenju prikupljaju se automatski prilikom korištenja Usluge.',
            2: 'Podaci o korištenju mogu uključivati informacije kao što su Internet protokol adresa Vašeg uređaja (npr. IP adresa), tip pretraživača, verzija pretraživača, stranice naše Usluge koje posjećujete, datum i vrijeme Vašeg posjeta, vrijeme provedeno na tim stranicama, jedinstveni identifikatori uređaja i drugi dijagnostički podaci.',
            3: 'Kada pristupate Usluzi putem mobilnog uređaja, možemo automatski prikupiti određene informacije, uključujući, ali ne ograničavajući se na, tip mobilnog uređaja koji koristite, jedinstveni ID Vašeg mobilnog uređaja, IP adresu Vašeg mobilnog uređaja, operativni sistem Vašeg mobilnog uređaja, tip mobilnog Internet pretraživača koji koristite, jedinstvene identifikatore uređaja i druge dijagnostičke podatke.',
            4: 'Možemo također prikupiti informacije koje Vaš pretraživač šalje svaki put kada posjetite našu Uslugu ili kada pristupate Usluzi putem mobilnog uređaja.',
          },
        },
      },

      useOfPersonalData: {
        title: 'Upotreba Vaših Ličnih Podataka',
        purposes: {
          description:
            'Kompanija može koristiti Lične Podatke u sljedeće svrhe:',
          1: {
            title: 'Za pružanje i održavanje naše Usluge,',
            description: ', uključujući praćenje korištenja naše Usluge.',
          },
          2: {
            title: 'Za upravljanje Vašim Računom:',
            description:
              'da upravlja Vašom registracijom kao korisnika Usluge. Lični podaci koje pružate mogu Vam omogućiti pristup različitim funkcionalnostima Usluge koje su dostupne Vama kao registriranom korisniku.',
          },
          3: {
            title: 'Za izvršenje ugovora:',
            description:
              'razvoj, usklađivanje i preuzimanje ugovora o kupovini proizvoda, stavki ili usluga koje ste kupili ili bilo kojeg drugog ugovora s nama putem Usluge.',
          },
          4: {
            title: 'Da Vas kontaktiramo:',
            description:
              'Da Vas kontaktiramo putem e-pošte, telefonskih poziva, SMS-a ili drugih ekvivalentnih oblika elektronske komunikacije, kao što su push obavijesti mobilnih aplikacija o ažuriranjima ili informativne komunikacije vezane za funkcionalnosti, proizvode ili ugovorene usluge, uključujući sigurnosna ažuriranja, kada je to potrebno ili razumno za njihovu implementaciju.',
          },
          5: {
            title: 'Da Vam pružimo',
            description:
              'novosti, posebne ponude i opće informacije o drugim robama, uslugama i događajima koje nudimo, a koji su slični onima koje ste već kupili ili o kojima ste pitali, osim ako ste se odlučili ne primati takve informacije.',
          },
          6: {
            title: 'Za upravljanje Vašim zahtjevima:',
            description:
              'Da obradimo i upravljamo Vašim zahtjevima prema nama.',
          },
          7: {
            title: 'Za poslovne transfere:',
            description:
              'Možemo koristiti Vaše informacije za procjenu ili sprovođenje spajanja, prodaje, restrukturiranja, reorganizacije, likvidacije ili druge prodaje ili transfera nekih ili svih naših sredstava, bilo kao dio tekuće djelatnosti ili kao dio bankrota, likvidacije ili sličnog postupka, u kojem su lični podaci koje imamo o korisnicima naše Usluge među prenesenim sredstvima.',
          },
          8: {
            title: 'Za druge svrhe:',
            description:
              'Možemo koristiti Vaše informacije u druge svrhe, kao što su analiza podataka, identifikacija trendova korištenja, utvrđivanje efikasnosti naših promotivnih kampanja i evaluacija i poboljšanje naše Usluge, proizvoda, usluga, marketinga i vašeg iskustva.',
          },
        },
        situations: {
          description:
            'Možemo podijeliti Vaše lične informacije u sljedećim situacijama:',
          1: {
            title: 'S Pružateljima Usluga:',
            description:
              'Možemo dijeliti Vaše lične informacije sa Pružateljima Usluga kako bismo pratili i analizirali korištenje naše Usluge, da Vas kontaktiramo.',
          },
          2: {
            title: 'Za poslovne transfere:',
            description:
              'Možemo dijeliti ili prenijeti Vaše lične informacije u vezi sa, ili tokom pregovora o, bilo kojem spajanju, prodaji imovine Kompanije, finansiranju ili akviziciji dijela ili cjelokupnog našeg poslovanja drugoj kompaniji.',
          },
          3: {
            title: 'S Povezanim Ljudima:',
            description:
              'Možemo dijeliti Vaše informacije s našim povezanim osobama, u kojem slučaju ćemo zahtijevati da ti povezani ljudi poštuju ovu Politiku Privatnosti. Povezani ljudi uključuju našu matičnu kompaniju i sve druge podružnice, partnere u zajedničkim poduhvatima ili druge kompanije koje kontroliramo ili koje su pod zajedničkom kontrolom s nama.',
          },
          4: {
            title: 'S poslovnim partnerima:',
            description:
              'Možemo dijeliti Vaše informacije s našim poslovnim partnerima kako bismo Vam ponudili određene proizvode, usluge ili promocije.',
          },
          5: {
            title: 'S drugim korisnicima:',
            description:
              'kada dijelite lične informacije ili na drugi način komunicirate u javnim područjima s drugim korisnicima, takve informacije mogu biti vidljive svim korisnicima i mogu biti javno distribuirane izvan.',
          },
          6: {
            title: 'Sa Vašim pristankom:',
            description:
              'Možemo otkriti Vaše lične informacije u bilo koju drugu svrhu uz Vaš pristanak.',
          },
        },
      },

      retentionOfPersonalData: {
        title: 'Zadržavanje Vaših Ličnih Podataka',
        points: {
          1: 'Kompanija će zadržati Vaše Lične Podatke samo onoliko dugo koliko je potrebno za svrhe navedene u ovoj Politici Privatnosti. Zadržat ćemo i koristiti Vaše Lične Podatke u obimu potrebnom za usklađivanje s našim zakonskim obavezama (na primjer, ako smo dužni zadržati vaše podatke u skladu s važećim zakonima), rješavanje sporova i provođenje naših pravnih ugovora i politika.',
          2: 'Kompanija će također zadržati podatke o korištenju za interne analitičke svrhe. Podaci o korištenju se obično zadržavaju kraće vrijeme, osim kada se ovi podaci koriste za jačanje sigurnosti ili poboljšanje funkcionalnosti naše Usluge, ili kada smo zakonski obavezani zadržati ove podatke duže vrijeme.',
        },
      },

      transferOfYourPersonalData: {
        title: 'Prenos Vaših Ličnih Podataka',
        points: {
          1: 'Vaši podaci, uključujući Lične Podatke, obrađuju se u operativnim kancelarijama Kompanije i na bilo kojim drugim mjestima gdje se nalaze strane uključene u obradu. To znači da se ovi podaci mogu prenijeti — i čuvati na — računarima koji se nalaze izvan Vaše države, provincije, zemlje ili druge vladine jurisdikcije gdje se zakoni o zaštiti podataka mogu razlikovati od onih u Vašoj jurisdikciji.',
          2: 'Vaša saglasnost sa ovom Politikom Privatnosti, praćena Vašim dostavljanjem takvih informacija, predstavlja Vašu saglasnost za taj prenos.',
          3: 'Kompanija će preuzeti sve razumno potrebne mjere kako bi osigurala da se Vaši podaci obrađuju sigurno i u skladu sa ovom Politikom Privatnosti, i nijedan prenos Vaših Ličnih Podataka neće se dogoditi organizaciji ili zemlji osim ako nisu uspostavljene adekvatne kontrole, uključujući sigurnost Vaših podataka i drugih ličnih informacija.',
        },
      },

      deleteOfPersonalData: {
        title: 'Izbrišite Vaše Lične Podatke',
        points: {
          1: 'Imate pravo da izbrišete ili zatražite da Vam pomognemo u brisanju Ličnih Podataka koje smo prikupili o Vama.',
          2: 'Naša Usluga može Vam omogućiti da izbrišete određene informacije o Vama unutar Usluge.',
          3: 'Možete ažurirati, izmijeniti ili izbrisati svoje informacije u bilo kojem trenutku prijavom na svoj Račun, ako ga imate, i posjetom odjeljku postavki računa koji vam omogućava da upravljate svojim ličnim informacijama. Takođe možete kontaktirati nas da zatražite pristup, ispravku ili brisanje bilo kojih ličnih informacija koje ste nam dostavili.',
          4: 'Imajte na umu, međutim, da ćemo možda morati zadržati određene informacije kada imamo zakonsku obavezu ili zakonitu osnovu za to.',
        },
      },
      disclosureOfPersonalData: {
        title: 'Otkrivanje Vaših Ličnih Podataka',
        businessTransactions: {
          title: 'Poslovne Transakcije',
          description:
            'Ako je Kompanija uključena u spajanje, akviziciju ili prodaju imovine, Vaši Lični Podaci mogu biti preneseni. Obavijestit ćemo Vas prije nego što Vaši Lični Podaci budu preneseni i postanu predmet druge Politike Privatnosti.',
        },
        lawEnforcement: {
          title: 'Pravna Izvršna Tijela',
          description:
            'U određenim okolnostima, Kompanija može biti obavezna otkriti Vaše Lične Podatke ako to zahtijeva zakon ili u odgovoru na valjane zahtjeve javnih vlasti (npr. sud ili vladina agencija).',
        },
        otherLegalRequirements: {
          title: 'Ostale zakonske obaveze',
          description:
            'Kompanija može otkriti Vaše Lične Podatke u dobroj vjeri smatrajući da je takvo djelovanje neophodno za:',
          points: {
            1: 'Ispunjavanje zakonske obaveze',
            2: 'Zaštitu i obranu prava ili imovine Kompanije',
            3: 'Sprječavanje ili istraživanje mogućih nedoličnih radnji u vezi sa Uslugom',
            4: 'Zaštitu lične sigurnosti Korisnika Usluge ili javnosti',
            5: 'Zaštitu od pravne odgovornosti',
          },
        },
      },

      securityOfPersonalData: {
        title: 'Sigurnost Vaših Ličnih Podataka',
        description:
          'Sigurnost Vaših Ličnih Podataka nam je važna, ali imajte na umu da nijedna metoda prenosa putem Interneta, niti metoda elektronskog skladištenja nije 100% sigurna. Iako se trudimo koristiti komercijalno prihvatljive metode za zaštitu Vaših Ličnih Podataka, ne možemo garantirati njihovu apsolutnu sigurnost.',
      },
    },

    childrenPrivacy: {
      title: 'Privatnost Djece',
      points: {
        1: 'Naša Usluga ne adresira nikoga mlađeg od 13 godina. Ne prikupljamo namjerno lične podatke od nikoga mlađeg od 13 godina. Ako ste roditelj ili staratelj i svjesni ste da je Vaše dijete dostavilo Lične Podatke, molimo Vas da nas kontaktirate. Ako saznamo da smo prikupili Lične Podatke od nekoga mlađeg od 13 godina bez provjere roditeljskog pristanka, preduzet ćemo mjere da uklonimo te informacije sa naših servera.',
        2: 'Ako trebamo osloniti se na pristanak kao pravnu osnovu za obradu Vaših informacija, a Vaša zemlja zahtijeva pristanak roditelja, možemo zahtijevati pristanak Vašeg roditelja prije nego što prikupimo i koristimo te informacije.',
      },
    },
    linksToOtherWebsites: {
      title: 'Linkovi na Druge Web Stranice',
      points: {
        1: 'Naša Usluga može sadržavati linkove na druge web stranice koje ne upravljamo. Ako kliknete na link treće strane, bićete preusmjereni na tu stranicu treće strane. Snažno Vam savjetujemo da pregledate Politiku Privatnosti svake stranice koju posjetite.',
        2: 'Nemamo kontrolu nad i ne preuzimamo odgovornost za sadržaj, politike privatnosti ili prakse bilo kojih stranica ili usluga trećih strana.',
      },
    },
    changesToPrivacyPolicy: {
      title: 'Promjene u ovoj Politici Privatnosti',
      points: {
        1: 'Možemo povremeno ažurirati Našu Politiku Privatnosti. Obavijestit ćemo Vas o bilo kakvim promjenama objavljivanjem nove Politike Privatnosti na ovoj stranici.',
        2: 'Obavijestit ćemo Vas putem e-maila i/ili istaknute obavijesti na Našoj Usluzi, prije nego što promjena postane učinkovita i ažurirati datum "Posljednje ažurirano" na vrhu ove Politike Privatnosti.',
        3: 'Preporučuje se da periodično pregledate ovu Politiku Privatnosti zbog bilo kakvih promjena. Promjene u ovoj Politici Privatnosti su na snazi kada su objavljene na ovoj stranici.',
      },
    },
    contactUs: {
      title: 'Kontaktirajte nas',
      submit: 'Submit',
      fields: {
        name: 'Ime',
        companyName: 'Naziv kompanije',
        mobile: 'Telefon',
        inquirey: 'Upit',
        email: 'Email',
        message: 'Poruka',
      },
      placeHolders: {
        mobile: '(+20)',
        inquirey: 'Odaberite Upit',
        message: 'Napišite svoju poruku..',
      },
      validation: {
        name: 'Molimo unesite ispravno ime.',
        inquiry: 'Molimo odaberite vrstu upita.',
        phone: 'Molimo unesite broj telefona.',
        phoneNotValid: 'Molimo unesite ispravan broj telefona.',
        email: 'Molimo unesite ispravnu e-mail adresu.',
        message: 'Molimo unesite vašu poruku.',
      },
    },
  },
  page404: {
    heading: 'Nešto je pošlo po zlu!',
    paragraph:
      'Ova stranica ne postoji ili je uklonjena,predlažemo da se vratite kući..',
    link: 'Povratak kući',
  },
  footer: {
    bigScrappersWebsite: 'Web stranica Big Scrappers',
  },

  contactUs: {
    title: 'Kontaktirajte Nas',
    description:
      'Ako imate bilo kakvih pitanja u vezi s ovom politikom privatnosti, možete nas kontaktirati:',
    points: {
      1: 'Putem telefonskog broja: +201100820963',
    },
    buyScrap: 'kupovati otpad',
    sellScrap: 'prodavati otpad',
  },
  // contactUs: {
  //   message: 'Message',
  //   enterMessage: 'Enter message',
  //   requiredMessage: 'Message is required',
  //   mobile: 'Mobile',
  //   enterMobile: 'Enter mobile',
  //   requiredMobile: 'Mobile is required',
  //   invalidMobile: 'Please, enter a valid mobile number',
  //   email: 'Email',
  //   enterEmail: 'Enter email',
  //   requiredEmail: 'Email is required',
  //   invalidEmail: 'Please, enter a valid email',
  //   send: 'Send',
  //   sentSuccessfully: 'Sent Successfully!',
  // },
  stores: {
    title: 'dionice',
    noActiveStores: 'Izvinjavamo se, trenutno nema aktivnih prodavnica',
  },

  noData: 'nema podataka',

  noInternet: {
    lostConnection: 'Veza je izgubljena!',
    oops: 'Izvinjavamo se! Čini se da je došlo do prekida internet veze.Molimo vas da provjerite svoju mrežnu vezu i pokušate ponovo',
    tryAgain: 'pokušajte ponovo',
  },
};
