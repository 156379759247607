import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setLanguage } from '../Store/Language';

// components
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import TermsModal from '../Components/TermsModal';
import { closeTermsModal } from '../Store/uiSlice';

const LayoutWithNavAndFooter = () => {
  const [showContent, setShowContent] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const { language } = useSelector((state) => state.lang);
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const homePath = location.pathname.includes('/home');

  const handleOpenTermsModal = () => {
    setTermsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseTermsModal = () => {
    setTermsModalOpen(false);
    document.body.style.overflow = 'auto';
    dispatch(closeTermsModal());
  };

  useEffect(() => {
    const queryLang = location.search.split('=')[1];
    if (queryLang && queryLang !== language) {
      if (queryLang !== 'ar' && queryLang !== 'en') {
        navigate('404');
      } else {
        i18n.changeLanguage(queryLang);
        dispatch(setLanguage(queryLang));
        setShowContent(true);
      }
    } else {
      setShowContent(true);
    }
  }, [location]);

  return showContent ? (
    <>
      <Navbar onOpenTermsModal={handleOpenTermsModal} />
      <main
        className={`${!homePath ? 'main-content' : undefined} bg--main-stars`}
      >
        <Outlet />
      </main>
      <Footer onOpenTermsModal={handleOpenTermsModal} />
      <TermsModal open={termsModalOpen} onCancel={handleCloseTermsModal} />
    </>
  ) : (
    ''
  );
};

export default LayoutWithNavAndFooter;
